import './App.css';
import './bob_form.css';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Header from 'components/Header/Header.js';
import ChooseYourPassPage from 'pages/1_ChooseYourPassPage/ChooseYourPassPage.js'
import BillingPage from 'pages/3_BillingPage/BillingPage.js'
import useBobStore from 'store/bobStore.js';
import Loader from 'components/Loader/Loader.js';
import usePurchase from 'hooks/purchase.js';
import OnlinePaymentResultPage from 'pages/5_PaymentPages/OnlinePaymentResultPage/OnlinePaymentResultPage.js';
import PaymentInstructionsPage from 'pages/5_PaymentPages/PaymentInstructionsPage/PaymentInstructionsPage.js';
import ChangePaymentMethodPage from 'pages/5_PaymentPages/ChangePaymentMethodPage/ChangePaymentMethodPage.js';
import WhoIsComingToBobPage from 'pages/2_WhoIsComingPage/WhoIsComingPage.js';
import ReviewPage from 'pages/4_ReviewPage/ReviewPage.js';
import * as Sentry from "@sentry/react";
import { initOpenReplay, setOpenReplayUserId } from 'utils/openReplay.js';
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4";
import useMobile from 'hooks/mobile.js';

function App() {
  const oneTimeInitDone = useRef(false);
  const loading = useBobStore((state) => state.loading);
  const loadTicketTypes = useBobStore((state) => state.loadTicketTypes);
  const setLoading = useBobStore((state) => state.setLoading);
  const { purchase, loadPurchase } = usePurchase();
  const isMobile = useMobile();

  // GA
  // inspired by: https://www.jimraptis.com/blog/custom-hook-to-connect-google-analytics-in-react

  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GA4_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA4_ID);
      console.log('Google analytics started');

      const path = location.pathname + location.search
      console.log('GA4', { hitType: "pageview", page: path });
      ReactGA.send({ hitType: "pageview", page: path });
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    const path = location.pathname + location.search
    console.log('GA4', { hitType: "pageview", page: path });
    ReactGA.send({ hitType: "pageview", page: path });
  }, [location.pathname, location.search])

  //

  useEffect(() => {
    const oneTimeInitAsync = async () => {
      console.log('REACT_APP_BACKEND_BASE_URL: ', process.env.REACT_APP_BACKEND_BASE_URL);
      initOpenReplay();
      await loadTicketTypes();
      await loadPurchase();
      setLoading(false);
    };

    if (!oneTimeInitDone.current) {
      oneTimeInitDone.current = true;
      oneTimeInitAsync();
    }
  }, [loadPurchase, loadTicketTypes, setLoading]);

  useEffect(() => {
    setOpenReplayUserId(purchase?.id);
  }, [purchase]);

  return (
    <div className={isMobile ? 'mobile' : 'not_mobile'}>
      <Header></Header>
      {loading
        ? <Loader />
        : <Routes>
          <Route path="/" element={<ChooseYourPassPage />} />
          <Route path="/registration" element={<WhoIsComingToBobPage />} />
          <Route path="/billing" element={<BillingPage />} />
          <Route path="/review" element={<ReviewPage />} />
          <Route path="/payment/online" element={<OnlinePaymentResultPage />} />
          <Route path="/payment/instructions" element={<PaymentInstructionsPage />} />
          <Route path="/payment/change_payment_method" element={<ChangePaymentMethodPage />} />
        </Routes>}
    </div>
  );
}

//export default App;
export default Sentry.withProfiler(App);
