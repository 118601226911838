import './ReviewDetails.css';
import { React } from 'react';
import usePurchase from 'hooks/purchase.js';
import formatPrice from 'utils/formatPrice.js';
import useCountries from 'hooks/countries.js';

function ReviewDetails() {
  const { purchase, getPaymentMethodFiendlyName } = usePurchase();
  const { getCountryFriendlyName } = useCountries();

  const DetailField = ({ value, className = '' }) => (
    <div className={"bob_review_details__field " + className}>{value}</div>
  );

  return (
    <>
      <div className="pass_registration_container bob_form_container bob_review_details">
        <div className="bob_form_inner_container bob_form">
          <h3 className="no_top_padding">Are you happy with what you see? :)</h3>
          <br/>

          {purchase.tickets.map((ticket, idx) => (
            <div key={ticket.id}>

              <h3 className="no_top_padding">
                <u>Pass #{idx + 1}:</u> {ticket.ticketType.name}
                <span style={{float: 'right'}}>
                  {formatPrice(ticket.price)} {ticket.currency}
                </span>
              </h3>

              <DetailField value={ticket.clientName} />
              <DetailField value={ticket.clientEmail} />
              {(ticket.danceExperience || ticket.lastEvent || ticket.favoriteArtists || ticket.favoriteDj) && <br/>}
              <DetailField value={"Party days: " + (ticket.party ?? "None")} />
              <DetailField value={ticket.danceExperience} />
              <DetailField value={ticket.lastEvent} />
              <DetailField value={ticket.favoriteArtists} />
              <DetailField value={ticket.favoriteDj} />

              <hr/>
            </div>
          ))}

          <h3 className="small_bottom_padding">Billing address:</h3>

          <DetailField value={purchase.billingName} />
          <DetailField value={purchase.zipCode + ' ' + purchase.city} />
          <DetailField value={purchase.street} />
          <DetailField value={getCountryFriendlyName(purchase.country)} />

          {purchase.info && purchase.info != '' && <>
            <h3 className="small_bottom_padding">Note to the organizers:</h3>
            <DetailField value={purchase.info} />
          </>}

          <h3 className="small_bottom_padding">Payment method:</h3>

          <DetailField className="text_transform-uppercase" value={getPaymentMethodFiendlyName(purchase.paymentMethod)} />

        </div>
      </div>

    </>
  );
}

export default ReviewDetails;
