import './PassList.css';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import useBobStore from 'store/bobStore.js';
import formatPrice from 'utils/formatPrice.js';
import usePurchase from 'hooks/purchase.js';
import BobButton from 'components/Buttons/BobButton.js';
import useMobile from 'hooks/mobile.js';

function PassList() {
  const currency = useBobStore((state) => state.currency);
  const ticketTypes = useBobStore((state) => state.ticketTypes);
  const { purchase, getCartAmount, addToCart, removeFromCart, increaseCartAmount, decreaseCartAmount } = usePurchase();
  const [buttonBusy, setButtonBusy] = useState(null);
  const isMobile = useMobile();

  useEffect(() => {
    setButtonBusy(null);
  }, [purchase]);

  return (<>
    {pass_list()}
  </>)

  function pass_list() {
    if (ticketTypes.length == 0) {
      return (<>
        <div className="no_passes">There are no passes currently available. Please check back later.</div>
      </>)
    }

    return (
      <div className="pass_list_container">
        {ticketTypes.map((tickeType, idx) => {
          return (
            <div key={tickeType.id}>
              {passElement(tickeType)}
              {isMobile && idx != ticketTypes.length-1 && <div className="mobile_pass_separator">...</div>}
              <div className="pass_separator"></div>
            </div>
          )
        })
        }
      </div>
    )
  }

  function passElement(ticketType) {
    return (<>
      {isMobile && <div>

        <div className="pass_details">
          <div className="title">
            {ticketType.name}
          </div>
          <div className="sub_title">
            {ticketType.prices[currency] !== null ?
              <span className="price">
                {formatPrice(ticketType.prices[currency].price) + ' ' + ticketType.prices[currency].currency}
              </span>
              : <></>}
            {ticketType.prices[currency] !== null && ticketType.prices[currency].stopDate ?
              <span className="price_details">
                / UNTIL {moment(ticketType.prices[currency].stopDate).format('Do') + ' of ' + moment(ticketType.prices[currency].stopDate).format('MMMM')}
              </span>
              : <></>}
          </div>
          <div className="pass_description">
            {ticketType.description}
          </div>
        </div>

        <br />

        <div className="pass_image no_selection">
          <div
            className={'centered_container pass_image_block ' + (ticketType.prices[currency] === null ? ' pass_image_block-unavailable' : '')}
            style={{ backgroundImage: ticketType.imageFile && `url('${process.env.REACT_APP_BACKEND_BASE_URL}/ticket_assets/${ticketType.imageFile}')` }}
          >
            <div className="centered_item centered_item--cart_buttons">
              {ticketType.prices[currency] !== null ? cartButtons(ticketType) : <></>}
            </div>
          </div>
        </div>

      </div>}

      {!isMobile && <div className="pass">
        <div className="pass_image no_selection">
          <div
            className={'centered_container pass_image_block ' + (ticketType.prices[currency] === null ? ' pass_image_block-unavailable' : '')}
            style={{ backgroundImage: ticketType.imageFile && `url('${process.env.REACT_APP_BACKEND_BASE_URL}/ticket_assets/${ticketType.imageFile}')` }}
          >
            <div className="centered_item">
              {ticketType.prices[currency] !== null ? cartButtons(ticketType) : <></>}
            </div>
          </div>
        </div>
        <div className="pass_details">
          <div className="title">
            {ticketType.name}
          </div>
          <div className="sub_title">
            {ticketType.prices[currency] !== null ?
              <span className="price">
                {formatPrice(ticketType.prices[currency].price) + ' ' + ticketType.prices[currency].currency}
              </span>
              : <></>}
            {ticketType.prices[currency] !== null && ticketType.prices[currency].stopDate ?
              <span className="price_details">
                / UNTIL {moment(ticketType.prices[currency].stopDate).format('Do') + ' of ' + moment(ticketType.prices[currency].stopDate).format('MMMM')}
              </span>
              : <></>}
          </div>
          <div className="pass_description">
            {ticketType.description}
          </div>
        </div>
      </div>}
    </>);
  }

  function cartButtons(pass) {
    if (getCartAmount(pass.id) == 0) {
      return (
        <div style={{ opacity: 0.75 }}>
          <BobButton
            className="bob_button bob_button-solid_white pass_cart_button"
            isBusy={buttonBusy == 'ADD_TO_CART-' + pass.id}
            onClick={() => { setButtonBusy('ADD_TO_CART-' + pass.id); addToCart(pass.id) }}>
            Add to cart
          </BobButton>
        </div>
      )
    }
    return <>
      <div style={{ opacity: 0.75 }}>
        <div className="cart_counter">
          <div className="cart_counter_inner">
            <BobButton
              className="bob_button bob_button-solid_white buy_a_pass_counter_button"
              isBusy={buttonBusy == 'DEC_AMNT-' + pass.id}
              onClick={() => { setButtonBusy('DEC_AMNT-' + pass.id); decreaseCartAmount(pass.id) }}>
              -
            </BobButton>
            <span className="amount">{getCartAmount(pass.id)}</span>
            <BobButton
              className="bob_button bob_button-solid_white buy_a_pass_counter_button"
              isBusy={buttonBusy == 'INC_AMNT-' + pass.id}
              onClick={() => { setButtonBusy('INC_AMNT-' + pass.id); increaseCartAmount(pass.id) }}>
              +
            </BobButton>
          </div>
        </div>
        <br />
        <BobButton
          className="bob_button bob_button-solid_white pass_cart_button"
          isBusy={buttonBusy == 'ADD_TO_CART-' + pass.id}
          onClick={() => { setButtonBusy('ADD_TO_CART-' + pass.id); removeFromCart(pass.id) }}>
          <span className="pass_cart_button_added_to_cart">Pass added to cart</span>
          <span className="pass_cart_button_remove_from_cart">Remove from cart</span>
        </BobButton>
      </div>
    </>
  }
}

export default PassList;
