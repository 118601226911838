import './BillingForm.css';
import { React, useEffect } from 'react';
import Select from 'react-select'
import useCountries from 'hooks/countries.js';
import useForms from 'hooks/forms.js';
import { ReactComponent as IconHat } from 'icons/hat.svg';
import usePurchase from 'hooks/purchase.js';

function BillingField({ label, fieldName }) {
  const { getBillingField, setBillingField } = useForms();

  return <>
    <label>{label}</label>
    <input value={getBillingField(fieldName, null) ?? '' } onChange={(e) => setBillingField(fieldName, e.target.value) }></input>
  </>
}

function BillingCountryField() {
  const { getBillingField, setBillingField } = useForms();
  const { countryOptions } = useCountries();

  return <>
    <label>Country*:</label>
    <Select
      className="bob_react_select"
      classNamePrefix="react-select"
      value={countryOptions.find(c => { return getBillingField('country') == c.value; })}
      onChange={(val) => setBillingField('country', val.value)}
      options={countryOptions}
    />
  </>
}

function Billing() {
  return (
    <>
      <div className="billing_container bob_form_container">
        <div className="bob_form_inner_container bob_form">
          <h3 className="no_top_padding">Billing address:</h3>
          <div className="form_fixed_grid">
            <BillingField label="Name:*" fieldName="billingName" />
            <BillingField label="E-mail:*" fieldName="email" />
            <BillingField label="Phone:*" fieldName="phone" />
            <BillingField label="City:*" fieldName="city" />
            <BillingField label="Street:*" fieldName="street" />
            <BillingField label="ZIP code:*" fieldName="zipCode" />
            <BillingCountryField />
          </div>
        </div>
      </div>
    </>
  );
}

export default Billing;
