import './OnlinePaymentResultPage.css';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loader from 'components/Loader/Loader.js';
import Api from 'utils/Api.js';
import OnlinePaymentSucceeded from './components/OnlinePaymentSucceeded.js';
import OnlinePaymentFailed from './components/OnlinePaymentFailed.js';
import OnlinePaymentPending from './components/OnlinePaymentPending.js';
import OnlinePaymentCancelled from './components/OnlinePaymentCancelled.js';
import usePayment from 'hooks/payment.js';

function OnlinePaymentResultPage() {
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(true);
    const [searchParams] = useSearchParams();
    const purchaseId = searchParams.get('purchaseId');
    const paymentId = searchParams.get('paymentId');
    const { getPaymentStatus } = usePayment();

    useEffect(() => {
        const loadPaymentState = async () => {
            const status = await getPaymentStatus(paymentId);
            setPaymentStatus(status);
            setLoading(false);
        };

        loadPaymentState();
    }, [paymentId]);

    if (loading) return <Loader />;
    if (paymentStatus == 'SUCCEEDED') return <OnlinePaymentSucceeded />;
    if (paymentStatus == 'CANCELLED') return <OnlinePaymentCancelled purchaseId={purchaseId} />
    if (paymentStatus == 'FAILED') return <OnlinePaymentFailed purchaseId={purchaseId} />;
    return <OnlinePaymentPending />;
}

export default OnlinePaymentResultPage;
