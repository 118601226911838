import "./NoteField.css";
import { React } from 'react';
import useForms from 'hooks/forms.js';

function NoteField() {
  const { getBillingField, setBillingField } = useForms();


  // const [textareaheight, setTextareaheight] = useState(1); 
  
  // function handleChange(event) { 
    
  //   console.log( event.target.rows ) 
  //   const height = event.target.scrollHeight; 
  //   const rowHeight = 15; 
  //   const trows = Math.ceil(height / rowHeight) - 1; 

  //   console.log(trows, textareaheight);
    
  //   if (trows != textareaheight) { 
      
  //     setTextareaheight(trows); 
      
  //   } 
    
  // }   


  return (
    <>
      <div className="billing_container bob_form_container">
        <div className="bob_form_inner_container bob_form">
          <h3 className="no_top_padding">Note to the organizers:</h3>
          <textarea className="info_textarea"
            value={getBillingField('info')} onChange={(e) => setBillingField('info', e.target.value)}
            // rows={textareaheight} onChange={handleChange}
           />
        </div>
      </div>
    </>
  );
}

export default NoteField;
