import useBobStore from 'store/bobStore.js';
import useForms from 'hooks/forms';
import Api from 'utils/Api.js';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

const usePurchase = () => {
    const purchase = useBobStore(state => state.purchase);
    const purchaseId = useBobStore(state => state.purchaseId);
    const currency = useBobStore(state => state.currency);
    const setPurchase = useBobStore(state => state.setPurchase);
    const clearPurchase = useBobStore((state) => state.clearPurchase);
    const { clearForms, getBillingField, setBillingField, getTicketField, setTicketField } = useForms();

    const clearPurchaseAndForms = () => {
        clearPurchase();
        clearForms();
    };

    const handleApiResult = (result) => {
        if (result.success) {
            return true;
        }

        switch (result.error) {
            case 'PURCHASE_NOT_FOUND':
                console.warn('Server responded with INVALID_PURCHASE - clearing local storage');
                alert(`You cannot modify this order anymore - but don't worry, we've created you a new one. Please repeat your last operation.`);
                clearPurchaseAndForms();
                return false;
            case 'INVALID_PURCHASE_STATUS':
                console.warn('Server responded with INVALID_PURCHASE_STATUS - clearing local storage');
                alert(`You cannot modify this order anymore - but don't worry, we've created you a new one. Please repeat your last operation.`);
                clearPurchaseAndForms();
                return false;
            default:
                console.error('Server responded with an error: ', result.error);
                alert('Server responded with an error.');
                return false;
        }
    };

    const loadPurchase = async () => {
        if (purchaseId != null) {

            const result = await Api.getPurchase(purchaseId);
            if (!handleApiResult(result)) return;

            const { purchase } = result;
            switch (purchase.status) {
                case 'NEW':
                    setPurchase(purchase);

                    // set billing fields
                    setBillingField('billingName', purchase.billingName);
                    setBillingField('email', purchase.email);
                    setBillingField('city', purchase.city);
                    setBillingField('street', purchase.street);
                    setBillingField('zipCode', purchase.zipCode);
                    setBillingField('phone', purchase.phone);
                    setBillingField('country', purchase.country);
                    setBillingField('info', purchase.info);

                    setBillingField('paymentMethod', purchase.paymentMethod);
                    setBillingField('agreeTermsConditions', purchase.agreeTermsConditions);
                    setBillingField('agreeGdpr', purchase.agreeGdpr);
                    setBillingField('agreeKeepPersonalInfo', purchase.agreeKeepPersonalInfo);

                    // set ticket fields
                    purchase.tickets.forEach((ticket) => {
                        setTicketField(ticket.id, 'clientName', ticket.clientName);
                        setTicketField(ticket.id, 'clientEmail', ticket.clientEmail);
                        setTicketField(ticket.id, 'party', ticket.party);
                        setTicketField(ticket.id, 'danceExperience', ticket.danceExperience);
                        setTicketField(ticket.id, 'lastEvent', ticket.lastEvent);
                        setTicketField(ticket.id, 'favoriteArtists', ticket.favoriteArtists);
                        setTicketField(ticket.id, 'favoriteDj', ticket.favoriteDj);
                    });
                    break;

                case 'SUBMITTED':
                case 'PAID':
                case 'CANCELLED':
                case 'DELETED':
                    const errorMessage1 = `Purchase ${purchase.id} has been ${purchase.status} - it shouldn't end up here`;
                    console.error(errorMessage1);
                    Sentry.captureMessage(errorMessage1);
                    clearPurchaseAndForms();
                    break;

                default:
                    const errorMessage2 = `Prucase ${purchase.id} has an unknown status: ${purchase.status}`;
                    console.error(errorMessage2);
                    Sentry.captureMessage(errorMessage2);
                    clearPurchaseAndForms();
                    break;
            }
        }
    };

    const savePurchase = async (options = {}) => {
        const updatedPurchase = JSON.parse(JSON.stringify(purchase));

        // set billing fields
        updatedPurchase.billingName = getBillingField('billingName');
        updatedPurchase.email = getBillingField('email');
        updatedPurchase.phone = getBillingField('phone');
        updatedPurchase.city = getBillingField('city');
        updatedPurchase.street = getBillingField('street');
        updatedPurchase.zipCode = getBillingField('zipCode');
        updatedPurchase.country = getBillingField('country');
        updatedPurchase.info = getBillingField('info');

        updatedPurchase.paymentMethod = getBillingField('paymentMethod');
        updatedPurchase.agreeTermsConditions = getBillingField('agreeTermsConditions') === true;
        updatedPurchase.agreeGdpr = getBillingField('agreeGdpr') === true;
        updatedPurchase.agreeKeepPersonalInfo = getBillingField('agreeKeepPersonalInfo') === true;

        // set ticket fields
        updatedPurchase.tickets.forEach((ticket) => {
            ticket.clientName = getTicketField(ticket.id, 'clientName');
            ticket.clientEmail = getTicketField(ticket.id, 'clientEmail');
            ticket.party = getTicketField(ticket.id, 'party');
            ticket.danceExperience = getTicketField(ticket.id, 'danceExperience');
            ticket.lastEvent = getTicketField(ticket.id, 'lastEvent');
            ticket.favoriteArtists = getTicketField(ticket.id, 'favoriteArtists');
            ticket.favoriteDj = getTicketField(ticket.id, 'favoriteDj');
        });

        try {
            const params = {
                submit: options.submit === true,
            }

            const result = await Api.setPurchaseFields(updatedPurchase.id, updatedPurchase, params);
            if (!handleApiResult(result)) return;

            const newPurchase = result.purchase;
            console.log('Server returned purchase:', newPurchase);

            setPurchase(newPurchase);

            return newPurchase;

        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            alert('Error saving purchase!');
        }
    };

    const switchCurrency = async (currency) => {
        try {
            const result = await Api.changeCurrency(purchase.id, currency);
            if (!handleApiResult(result)) return;

            const newPurchase = result.purchase;
            console.log('Server returned purchase:', newPurchase);

            setPurchase(newPurchase);

        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            alert('Error switching currency!');
        }
    };

    const getCartAmount = (ticketTypeId) => {
        var result = 0;
        purchase.tickets.forEach((ticket) => {
            if (ticket.ticketTypeId == ticketTypeId) {
                result++;
            }
        });
        return result;
    };

    const getCartQuantity = () => {
        return purchase.tickets.length;
    };

    const getTotalBasePrice = () => {
        var result = 0;
        purchase.tickets.forEach((ticket) => {
            result += ticket.priceBase;
        });
        return result;
    };

    const getTotalPrice = () => {
        var result = 0;
        purchase.tickets.forEach((ticket) => {
            result += ticket.price;
        });
        return result;
    }

    const getDiscountTotal = () => {
        var result = 0;
        purchase.tickets.forEach((ticket) => {
            result += ticket.discount;
        });
        return result;
    }

    const getTicketsByticketTypeId = (ticketTypeId) => {
        return purchase.tickets.filter((ticket) => {
            return ticket.ticketTypeId == ticketTypeId;
        });
    };

    const addToCart = async (ticketTypeId) => {
        let finalPurchaseId = purchaseId;

        if (finalPurchaseId == null) {
            const createResult = await Api.createPurchase(currency);
            if (!handleApiResult(createResult)) return;

            setPurchase(createResult.purchase);
            finalPurchaseId = createResult.purchase.id;     // we need this variable because global 'purchaseId' will not change during this run
            
            console.log('Created new purchase:', finalPurchaseId);
        }

        const result = await Api.addToCart(finalPurchaseId, ticketTypeId);
        if (!handleApiResult(result)) return;

        const newPurchase = result.purchase;

        setPurchase(newPurchase);
    };

    const increaseCartAmount = async (ticketTypeId) => {
        addToCart(ticketTypeId);
    };

    const decreaseCartAmount = async (ticketTypeId) => {
        removeInstanceFromCart(_.last(getTicketsByticketTypeId(ticketTypeId)).id);
    };

    const removeFromCart = async (ticketTypeId) => {
        const ticketIds = purchase.tickets.filter((ticket) => {
            return ticket.ticketTypeId == ticketTypeId;
        }).map((ticket) => {
            return ticket.id;
        });

        const result = await Api.removeFromCart(purchaseId, ticketIds);
        if (!handleApiResult(result)) return;

        const newPurchase = result.purchase;
        console.log('Server returned purchase:', newPurchase);

        setPurchase(newPurchase);
    };

    const removeInstanceFromCart = async (purchaseticketTypeId) => {
        const ticketIds = [purchaseticketTypeId];

        const result = await Api.removeFromCart(purchaseId, ticketIds);
        if (!handleApiResult(result)) return;

        const newPurchase = result.purchase;
        console.log('Server returned purchase:', newPurchase);

        setPurchase(newPurchase);
    };

    const getCart = () => {
        return purchase.tickets;
    };

    const applyPromoCode = async (promoCode) => {
        try {
            const result = await Api.applyPromoCode(purchaseId, promoCode);

            // handle this one silently - will display a message to the user
            if (!result.success && result.error == 'INVALID_PROMO_CODE') {
                return false;
            }

            if (!handleApiResult(result)) return;

            const newPurchase = result.purchase;
            console.log('Server returned purchase:', newPurchase);

            setPurchase(newPurchase);

            return true;
        } catch (err) {
            return false;
        }
    };

    const removePromoCode = async () => {
        const result = await Api.removePromoCode(purchaseId);
        if (!handleApiResult(result)) return;

        const newPurchase = result.purchase;
        console.log('Server returned purchase:', newPurchase);

        setPurchase(newPurchase);
    };

    const getPaymentMethodFiendlyName = (paymentMethod) => {
        switch (paymentMethod) {
            case 'ONLINE': return 'Online payment by card';
            case 'TRANSFER': return 'Bank transfer to the organizers';
            case 'CASH': return 'Cash to ambassador';
            case 'CASH_TO_ORGANIZERS': return 'Cash to organizers at check-in';
            default: return paymentMethod;
        }
    };

    return {
        purchase,
        loadPurchase,
        savePurchase,
        getCart,
        getCartAmount,
        getCartQuantity,
        getTotalBasePrice,
        getDiscountTotal,
        addToCart,
        removeFromCart,
        removeInstanceFromCart,
        getTicketsByticketTypeId,
        increaseCartAmount,
        decreaseCartAmount,
        getTotalPrice,
        applyPromoCode,
        removePromoCode,
        clearPurchaseAndForms,
        getPaymentMethodFiendlyName,
        switchCurrency,
    };
};

export default usePurchase;
