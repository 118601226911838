import './CheckoutCart.css';
import { React } from 'react';
import CartCore from 'components/CartCore/CartCore.js';

function CheckoutCart() {
  return (
    <>
      <div className="cart_container">
        <div className="cart_table_container">

          {/* TODO: fix styles */}
          <div className="checkoutcart_header">
            <h3 style={{fontSize: '1.2rem'}}>Your selection:</h3>
          </div>
          <CartCore></CartCore>
        </div>
      </div>
    </>
  );
}

export default CheckoutCart;
