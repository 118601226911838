import ChangePaymentMethodButton from 'components/Buttons/ChangePaymentMethodButton.js';
import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';

function TransferPaymentInfo({ purchase }) {
    return (
        <>
            <div className="payment_finished_page_container">

                <h1>Checkout complete</h1>

                <p className="payment_finished_page_paragraph-secondary">
                    Thank you for your order. We are excited to see you in Budapest!
                    <br />
                    <br />
                    You've decied to pay for your tickets through bank transfer. Please complete your bank transfer according to the information below:<br />
                </p>
                <br />
                <br />
                <div className="payment_finished_page_paragraph payment_finished_page_paragraph-secondary">

                    <div className="payment_finished_transfer_instructions">
                        <div className="payment_finished_transfer_instructions payment_finished_transfer_instruction">
                            <label>IBAN:</label>
                            <span>HU36 1620 0106 1166 2301 0000 0000</span>
                        </div>
                        <div className="payment_finished_transfer_instructions payment_finished_transfer_instruction">
                            <label>SWIFT/BIC code:</label>
                            <span>HBWEHUHB</span>
                        </div>
                        <div className="payment_finished_transfer_instructions payment_finished_transfer_instruction">
                            <label>Recipient name:</label>
                            <span>CoDesign Bt.</span>
                        </div>
                        <div className="payment_finished_transfer_instructions payment_finished_transfer_instruction">
                            <label>Bank name:</label>
                            <span>Magnet Bank, 1062 Budapest, Andrassy Ut 98</span>
                        </div>
                        <div className="payment_finished_transfer_instructions payment_finished_transfer_instruction">
                            <label>Amount:</label>
                            <span>{purchase.value} {purchase.currency}</span>
                        </div>
                        <div className="payment_finished_transfer_instructions payment_finished_transfer_instruction">
                            <label>In the description please enter your billing name:</label>
                            <span>{purchase.billingName}</span>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <p className="payment_finished_page_paragraph-secondary">We have also e-mailed you these instructions ;)</p>
                <br />
                <span style={{ fontSize: '4rem' }}>💸</span>
                <br />
                <br />
                <br />
                <br />

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                    <ChangePaymentMethodButton purchaseId={purchase.id} />
                </div>
            </div>
        </>);
}

export default TransferPaymentInfo;
