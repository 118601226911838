import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';

function OnlinePaymentSucceeded() {
    return (
        <>
            <div className="payment_finished_page_container">
                
                <h1>Payment Succeeded</h1>

                <p>Thank you and see you soon in Budapest!</p>
                <p><img src={require('./payment-succeeded-image.png')} alt="Payment Succeeded" /></p>

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                </div>
            </div>
        </>
    );
}

export default OnlinePaymentSucceeded;
