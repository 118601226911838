import OrderSummaryInner from 'components/OrderSummaryInner/OrderSummaryInner.js'
import PromoCodeEditable from 'components/PromoCodeEditable/PromoCodeEditable.js';
import React from 'react'
import { useState } from 'react';
import usePurchase from 'hooks/purchase.js';
import useForms from 'hooks/forms.js';
import { useNavigate } from 'react-router-dom';
import BobButton from 'components/Buttons/BobButton.js';
import isValueEmpty from 'utils/isValueEmpty.js';

const WhoIsComingSummary = () => {
  const { purchase, getCart, savePurchase } = usePurchase();
  const { getTicketField, setTicketField } = useForms();
  const navigate = useNavigate();
  const [ proceedButtonBusy, setProceedButtonBusy ] = useState(false);
  const [ saveButtonBusy, setSaveButtonBusy ] = useState(false);
  const promoCodeDisabled = process.env.REACT_APP_PROMO_CODE_DISABLED == 'true';

  const next = async () => {
    // >> DEVELOPMENT MODE >>
    if (process.env.REACT_APP_DEV_AUTOFILL === 'true') {
      purchase.tickets.forEach((ticket, idx) => {
        if (isValueEmpty(getTicketField(ticket.id, 'clientName'))) setTicketField(ticket.id, 'clientName', '[name]');
        if (isValueEmpty(getTicketField(ticket.id, 'clientEmail'))) setTicketField(ticket.id, 'clientEmail', '[email]');
      });
    }
    // << DEVELOPMENT MODE <<

    if (validatePassRegistrations()) {
      setProceedButtonBusy(true);
      if (await savePurchase()) {
        navigate('/billing');
      } else {
        setProceedButtonBusy(false);
      }
    }
  };

  const back = async () => {
    navigate('/');
  };

  const save = async () => {
    setSaveButtonBusy(true);
    if (await savePurchase()) {
      window.location.href = process.env.REACT_APP_BACK_TO_BOB_SITE_URL;
    } else {
      setSaveButtonBusy(false);
    }
  };

  function validatePassRegistrations() {
    const tickets = getCart();

    if (tickets.length == 0) {
      alert('No items in cart');
      return false;
    }

    var isValid = true;
    tickets.some((ticket, idx) => {
      if (isValueEmpty(getTicketField(ticket.id, 'clientName'))) {
        alert('Please enter name for pass ' + (idx + 1));
        isValid = false;
        return true;
      }
      if (isValueEmpty(getTicketField(ticket.id, 'clientEmail'))) {
        alert('Please enter email for pass ' + (idx + 1));
        isValid = false;
        return true;
      }
      return false;
    });
    if (!isValid) {
      return false;
    }

    return true;
  }

  return (
    <>
      <div className="order_summary_container">
        <div className="order_summary_inner_container">
          <h3>Order summary:</h3>
          <h4>Promo code: {promoCodeDisabled && <span style={{fontWeight: 300}}>(not available yet)</span>}</h4>
          <div className="order_summary_grid">
            <PromoCodeEditable />

            <OrderSummaryInner />

            <div className="separator" />

            <BobButton onClick={next} className="bob_button-solid_gold width_100_percent" isBusy={proceedButtonBusy} >
              Proceed to billing information
            </BobButton>

            <BobButton onClick={back} className="bob_button-transparent_white bob_button-solid_black_on_hover width_100_percent">
              Back to choose your pass
            </BobButton>

            <BobButton onClick={save} className="bob_button-transparent_white bob_button-solid_black_on_hover width_100_percent" isBusy={saveButtonBusy}>
              Save cart & back to BOB site
            </BobButton>
          </div>
        </div>
      </div>

    </>
  )
}

export default WhoIsComingSummary