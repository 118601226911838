import CountryList from 'country-list';
import { useState, useEffect } from 'react';

const useCountries = () => {
    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        const populate = () => {
            if (countryOptions.length == 0) {
                const countryOptions = CountryList.getData().map(country => {
                    return {
                        value: country.code,
                        label: country.name
                    }
                });
                countryOptions.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
                setCountryOptions(countryOptions);
            }
        };

        populate();
    }, [countryOptions])

    const getCountryFriendlyName = (countryCode) => {
        const countryObj = CountryList.getData().find(country => country.code === countryCode);
        return countryObj?.name ?? countryCode;
    };

    return { countryOptions, getCountryFriendlyName };
};

export default useCountries;
