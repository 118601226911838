import ReviewOrderSummary from './components/ReviewSummary.js';
import CurrentStep from 'components/CurrentStep/CurrentStep.js';
import ConnectWithUs from 'components/ConnectWithUs/ConnectWithUs.js';
import ReviewDetails from './components/ReviewDetails.js';
import useVerifyCartNotEmpty from 'hooks/verifyCartNotEmpty.js';

function ReviewPage() {
  useVerifyCartNotEmpty();

  return (
    <>
      <h1>Final Review</h1>
      <CurrentStep index={4} />
      <div className="min_height_container_for_connectwithus">
        <div className="checkout_page_container">
          <div className="checkout_page_grid">
            <div className="checkout_page_left_grid">
              <ReviewDetails></ReviewDetails>
            </div>
            <div className="checkout_page_right_grid checkout_page_sticky">
              <div>
                <ReviewOrderSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConnectWithUs />
    </>
  );
}

export default ReviewPage;
