const createSlice = (set, get) => ({
    purchaseId: null,
    purchase: {
        tickets: [],
    },

    setPurchaseId(purchaseId) {
        set({ purchaseId });
    },

    setPurchase(purchase) {
        set({ purchase,
            purchaseId: purchase.id,
            currency: purchase.currency,
        });
        console.log('SET purchase', purchase.id, purchase.currency);
        console.log('SET', { purchase,
            purchaseId: purchase.id,
            currency: purchase.currency,
        });
    },

    clearPurchase: () => {
        set({
            purchaseId: null,
            purchase: {
                tickets: [],
            },
        });
    },
});

export default createSlice;