import './CurrentStep.css';
import React from 'react';
import { Link } from 'react-router-dom';
import useMobile from 'hooks/mobile.js';

const CurrentStep = ({ index: currentIndex }) => {
  const steps = {
    1: { path: '/', name: 'Choose your pass' },
    2: { path: '/registration', name: 'Who is coming to BOB' },
    3: { path: '/billing', name: 'Billing information' },
    4: { path: '/review', name: 'Final review' },
  };
  const isMobile = useMobile();

  return (
    <div className="current_step_container">
      <div className="current_step_steps">
        {Object.entries(steps).map(([index, step]) => (
          <span key={index}>
            {isMobile && index == currentIndex && 
             <span className={"current_step_step "}>
                Step {index} / {Object.keys(steps).length}
              </span>}
            {!isMobile && index != 1 && <span className="current_step_separator">&gt;</span>}
            {!isMobile && index < currentIndex &&
              <Link to={step.path} className={"current_step_step " + (index == currentIndex ? 'current_step_step--active' : '')}>
                {index}. {step.name}
              </Link>}
            {!isMobile && index >= currentIndex &&
              <span className={"current_step_step " + (index == currentIndex ? 'current_step_step--active' : '')}>
                {index}. {step.name}
              </span>}
          </span>
        ))}
      </div>
    </div>
  )
}

export default CurrentStep