import './Header.css';
import { ReactComponent as IconHat } from 'icons/hat.svg';
import CurrencySwitcher from './components/CurrencySwitcher.js';
import CartIcon from './components/CartIcon.js';
import useMobile from 'hooks/mobile.js';

function Header() {
  const isMobile = useMobile();

  return (
    <>
      <div className="header_container">
        {isMobile && <div className="header_table">
          <div className="header_item">
            <a className="header_link" href={process.env.REACT_APP_BOB_HOMEPAGE}>
              <IconHat className="logo_icon"></IconHat>
              <span style={{ marginLeft: '1.25rem' }}>BOB SITE</span>
            </a>
          </div>

          <div className="header_item header_item-remaining"></div>

          <div className="header_item">
            <CurrencySwitcher />
          </div>

          <div className="header_item" style={{ paddingLeft: '0.35rem' }}>
            <CartIcon />
          </div>

        </div>
        }

        {!isMobile &&
          <div className="header_table">
            <div className="header_item">
              <a className="header_link" href={process.env.REACT_APP_BOB_HOMEPAGE}>
                <IconHat className="logo_icon"></IconHat>
                <span style={{ marginLeft: '1.25rem' }}>BACK TO BOB SITE</span>
              </a>
            </div>

            <div className="header_item header_item-remaining"></div>

            <div className="header_item">
              <a className="header_link" href={process.env.REACT_APP_AMBASSADOR_LOGIN_URL}>Ambassador login</a>
            </div>

            <div className="header_item">
              <div className="header_separator"></div>
            </div>

            <div className="header_item">
              <CurrencySwitcher />
            </div>

            <div className="header_item" style={{ paddingLeft: '0.35rem' }}>
              <CartIcon />
            </div>
          </div>}
      </div>
    </>
  );
}

export default Header;
