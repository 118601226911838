import './CartCore.css';
import { React } from 'react';
import useBobStore from 'store/bobStore.js';
import formatPrice from 'utils/formatPrice.js';
import usePurchase from 'hooks/purchase.js';
import useMobile from 'hooks/mobile.js';

function CartCore() {
  const currency = useBobStore((state) => state.currency);
  const { purchase, increaseCartAmount, decreaseCartAmount } = usePurchase();
  const isMobile = useMobile();

  let ticketsGrouped = {};
  let total = 0;

  purchase.tickets.forEach((ticket) => {
    ticketsGrouped[ticket.ticketTypeId] = ticketsGrouped[ticket.ticketTypeId] ?? {
      ticketType: ticket.ticketType,
      tickets: [],
      amount: 0,
      price: 0,
    };
    ticketsGrouped[ticket.ticketTypeId].tickets.push(ticket);
    ticketsGrouped[ticket.ticketTypeId].price += ticket.price;
    ticketsGrouped[ticket.ticketTypeId].amount += 1;
    total += ticket.price;
  });

  return (
    <>
      <table className="cart_table">
        <tbody>
          {cartItems()}
          {cartSeparator()}
          {cartTotal()}
        </tbody>
      </table>
    </>
  );

  function cartItems() {
    if (purchase.tickets.length == 0) {
      return <tr className="cart_item">
        <td className="cart_empty_message" colSpan="100">
          Your cart is empty.
        </td>
      </tr>
    }

    return Object.values(ticketsGrouped)
      .map((ticketGroup) => {
        return (
          <tr className="cart_item" key={ticketGroup.ticketType.id}>
            <td className="cart_name">
              {isMobile && <span style={{paddingRight: '0.5rem'}}>{ticketGroup.amount}x</span>}
              {ticketGroup.ticketType.name}
              {ticketGroup.ticketType.status != 'ACTIVE' && <span className="cart_item_unavailable"> - UNAVAILABLE</span>}
            </td>
            {!isMobile &&
            <td className="cart_buttons">
              <span className="button cart_button no_selection" onClick={() => decreaseCartAmount(ticketGroup.ticketType.id)}>-</span>
              {ticketGroup.amount}
              <span className="button cart_button no_selection" onClick={() => increaseCartAmount(ticketGroup.ticketType.id)}>+</span>
            </td>}
            <td className="cart_amount">
              <span style={{whiteSpace: "nowrap"}}>{formatPrice(ticketGroup.price)}&nbsp;{currency}</span>
            </td>
          </tr>
        )
      })
  }

  function cartSeparator() {
    return (<>
      <tr className="cart_separator">
        <td colSpan="100">
          <hr/>
        </td>
      </tr>
    </>)
  }

  function cartTotal() {
    return (<>
      <tr className="cart_total">
        <td className="cart_name">
          TOTAL
        </td>
        {!isMobile && <td></td>}
        <td className="cart_amount">
          <span style={{whiteSpace: "nowrap"}}>{formatPrice(total)} {currency}</span>
        </td>
      </tr>
    </>)
  }
}

export default CartCore;
