import React from 'react'
import { ReactComponent as IconLoader } from 'icons/loader.svg';

const BobButton = ({
    children = "[ BOB BUTTON ]",
    className = "bob_button-solid_white",
    isBusy = false,
    disabled = false,
    onClick = (() => { }) }) => {

    return (
        <button
            className={"bob_button " + className + " " + (isBusy ? "bob_button-busy" : "")}
            onClick={onClick}
            disabled={disabled === true || disabled === "true" || isBusy}>
                {isBusy && <IconLoader className="bob_button--icon" />}
                <span className={isBusy ? "bob_button--hide_content" : ""}>
                    {children}
                </span>
        </button>
    )
}

export default BobButton