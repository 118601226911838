import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import createPurchaseSlice from './slices/purchaseSlice.js';
import createPassesSlice from './slices/passesSlice.js';
import createLoadingSlice from './slices/loadingSlice.js';
import createFormSlice from './slices/formSlice.js';
import createCurrencySlice from './slices/currencySlice.js';

const useBobStore = create(
  devtools(       // allow using Redux DevTools
    persist(        // persist select states in the browser's storage
      (set, get) => ({
        ...createCurrencySlice(set, get),
        ...createFormSlice(set, get),
        ...createLoadingSlice(set, get),
        ...createPassesSlice(set, get),
        ...createPurchaseSlice(set, get),
      }),
      {
        name: 'bob-storage',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => {
          const include = [
            'purchaseId',
            // 'form',
          ]
          return Object.fromEntries(Object.entries(state).filter(([key]) => include.includes(key)));
        },
      })));

export default useBobStore;
