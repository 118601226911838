import ChangePaymentMethodButton from 'components/Buttons/ChangePaymentMethodButton.js';
import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';
import RetryPaymentButton from 'components/Buttons/RetryPaymentButton.js';

function OnlinePaymentFailed({ purchaseId }) {
    return (
        <>
            <div className="payment_finished_page_container">

                <h1>Payment Failed</h1>

                <p className="payment_finished_page_paragraph-secondary">We are sorry but your payment didn't go through :(</p>
                <p className="payment_finished_page_paragraph-secondary">Don't worry, you can retry your payment at any time using the Retry Payment button below.</p>

                <br/>
                <br/>

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                    <ChangePaymentMethodButton purchaseId={purchaseId} />
                    <RetryPaymentButton purchaseId={purchaseId} />
                </div>
                
                <br/>
                <br/>
                <br/>

                <p className="payment_finished_page_paragraph-secondary">If you need assistance, feel free to get in touch with us at <b><a className="white_link" href="mailto:info@breakonbudapest.com">info@breakonbudapest.com</a></b></p>
            </div>
        </>
    );
}

export default OnlinePaymentFailed;
