import './OrderSummaryInner.css';
import { React } from 'react';
import formatPrice from 'utils/formatPrice.js';
import usePurchase from 'hooks/purchase.js';
import useBobStore from 'store/bobStore.js';

function OrderSummaryInner() {
  const currency = useBobStore((state) => state.currency);
  const { getTotalBasePrice, getTotalPrice, getDiscountTotal } = usePurchase();

  return (
    <>
        <div className="order_summary_grid">

          <hr />

          <table className="order_summary_table">
            <tbody>
              {getDiscountTotal() > 0 &&
                <>
                  <tr>
                    <td className="order_summary_table--strikethrough">Regular price:</td>
                    <td className="order_summary_table_price order_summary_table--strikethrough">{formatPrice(getTotalBasePrice())} {currency}</td>
                  </tr>
                  <tr>
                    <td>Discount:</td>
                    <td className="order_summary_table_price">-{formatPrice(getDiscountTotal())} {currency}</td>
                  </tr>
                </>
              }
              <tr>
                <td>Order total:</td>
                <td className="order_summary_table_price">{formatPrice(getTotalPrice())} {currency}</td>
              </tr>
            </tbody>
          </table>

          <hr />

          <table className="order_summary_table">
            <tbody>
              <tr>
                <td>Amount due:</td>
                <td className="order_summary_table_price order_summary_table_price--larger">{formatPrice(getTotalPrice())} {currency}</td>
              </tr>
            </tbody>
          </table>

        </div>
        </>
  );
}

export default OrderSummaryInner;
