import { ClockLoader } from 'react-spinners';

function Loader() {
    return (<>
        <div className="centered_on_screen">
            <div className="loading">
                <ClockLoader color="#b79c77" />
            </div>
        </div>
    </>)
};

export default Loader;