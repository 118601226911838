import useBobStore from 'store/bobStore.js';
import _ from 'lodash';

const useForms = () => {
    const form = useBobStore((state) => state.form);
    const getForm = useBobStore((state) => state.getForm);
    const setForm = useBobStore((state) => state.setForm);
    const partyDays = ['Thursday', 'Friday', 'Saturday', 'Sunday'];

    const clearForms = () => {
        setForm({})
    };

    const getBillingField = (field) => {
        const values = getForm();
        return values.form['billing-' + field] ?? values.formDefaults['billing-' + field];;
    };

    const setBillingField = (field, value) => {
        const values = getForm();
        const form = _.clone(values.form);
        form['billing-' + field] = value;
        console.log('setBillingField', field, JSON.stringify(value));
        // debugger;
        setForm(form);
    };

    const getTicketField = (ticketId, field) => {
        const values = getForm();
        const result = values.form['ticket-' + ticketId + '-' + field];
        return result;
    };

    const setTicketField = (ticketId, field, value) => {
        const values = getForm();
        const form = _.clone(values.form);
        form['ticket-' + ticketId + '-' + field] = value;
        setForm(form);
    };

    const toggleTicketOption = (passKey, field, option) => {
        const optionString = getTicketField(passKey, field, {}) ?? '';
        const optionArray = (typeof optionString == 'string') && optionString != '' ? optionString.split(',') : [];
        if (optionArray.includes(option)) {
            optionArray.splice(optionArray.indexOf(option), 1);
        } else {
            optionArray.push(option);
        }
        optionArray.sort((a, b) => {
            return partyDays.indexOf(a) - partyDays.indexOf(b);
        });
        const newOptionString = optionArray.join(',');
        setTicketField(passKey, field, newOptionString);
    };

    const isTicketOptionEnabled = (passKey, field, option) => {
        const optionString = getTicketField(passKey, field, {}) ?? '';
        const optionArray = (typeof optionString == 'string') && optionString != '' ? optionString.split(',') : [];
        return optionArray.includes(option);
    };

    return {
        form,
        clearForms,
        getBillingField,
        setBillingField,
        getTicketField,
        setTicketField,
        isTicketOptionEnabled,
        toggleTicketOption,
        partyDays,
    };
};

export default useForms;
