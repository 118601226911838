import { React, useEffect, useState } from 'react';
import IconCheckCircle from 'icons/IconCheckCircle.js';
import IconCloseCircle from 'icons/IconCheckCircle.js';
import IconClockCircle from 'icons/IconClockCircle.js';
import usePurchase from 'hooks/purchase.js';
import useForms from 'hooks/forms.js';
import BobButton from 'components/Buttons/BobButton.js';

function PromoCodeEditable() {
  const { purchase, applyPromoCode: purchaseApplyPromoCode, removePromoCode: purchaseRemovePromoCode, getPaymentMethodFiendlyName } = usePurchase();
  const { getBillingField, setBillingField } = useForms();
  const promoCode = purchase.promoCode;
  const [promoCodeField, setPromoCodeField] = useState(promoCode ?? '');
  const [promoCodeStatus, setPromoCodeStatus] = useState('');
  const promoCodeDisabled = process.env.REACT_APP_PROMO_CODE_DISABLED == 'true';

  useEffect(() => {
    setPromoCodeField(promoCode ?? '');
    if (promoCode) {
      setPromoCodeStatus('APPLIED');
    } else {
      setPromoCodeStatus('');
    }
  }, [promoCode]);

  async function applyPromoCode() {
    setPromoCodeStatus('VALIDATING');
    const status = await purchaseApplyPromoCode((promoCodeField ?? '').toUpperCase());
    if (status == true) {
      // wait for the new purchase object to be loaded
    } else {
      setPromoCodeStatus('INVALID');
    }
  }

  async function removePromoCode() {
    // special handling for CASH (to ambassador) payment option (part 1)
    if (getBillingField('paymentMethod') == 'CASH') {
      if (!window.confirm('If you remove your promo code, payment method ' + getPaymentMethodFiendlyName('CASH') + ' will no longer be available. Are you sure to continue?')) {
        return;
      }
    }

    await purchaseRemovePromoCode();

    // special handling for CASH (to ambassador) payment option (part 2)
    if (getBillingField('paymentMethod') == 'CASH') {
      setBillingField('paymentMethod', 'ONLINE');
    }
  }

  function promoCodeFieldOnChange(promoCode) {
    setPromoCodeField(promoCode ?? '');
    if (promoCode == '' && promoCodeStatus == 'INVALID') {
      setPromoCodeStatus('');
    }
  }

  return (
    <>
          <div>
            <form onSubmit={applyPromoCodeSubmit}>
              <input
                className={"promo_code_field width_100_percent " + (promoCodeStatus == 'APPLIED' ? "promo_code_field--applied" : (promoCodeStatus == 'INVALID' ? "promo_code_field--invalid" : ""))}
                disabled={promoCodeDisabled || promoCodeStatus == 'APPLIED'}
                value={promoCodeField}
                onChange={(e) => promoCodeFieldOnChange(e.target.value)}
                autoComplete="off" />
              {promoCodeStatus == 'INVALID' &&
                <div className="validation_result validation_error"><IconCloseCircle /> We are sorry, this promo code is invalid.</div>
              }
              {promoCodeStatus == 'APPLIED' &&
                <div className="validation_result validation_success"><IconCheckCircle /> Promo code applied</div>
              }
              {promoCodeStatus == 'VALIDATING' &&
                <div className="validation_result validation_progress"><IconClockCircle /> Validating promo code...</div>
              }
            </form>
          </div>

          {!promoCodeDisabled && (promoCodeStatus == '' || promoCodeStatus == 'VALIDATING') &&
            <BobButton className="bob_button bob_button-solid_white width_100_percent"
              disabled={promoCodeDisabled || promoCodeField == '' || promoCodeStatus == 'VALIDATING'}
              onClick={() => applyPromoCode()}>
              Apply promo code
            </BobButton>
          }

          {promoCodeStatus == 'APPLIED' &&
            <BobButton className="bob_button bob_button-solid_black width_100_percent"
              disabled={promoCodeField == ''}
              onClick={() => removePromoCode()}>
              Remove promo code
            </BobButton>
          }

          {promoCodeStatus == 'INVALID' &&
            <BobButton className="bob_button bob_button-solid_white width_100_percent"
              disabled={promoCodeField == ''}
              onClick={() => applyPromoCode()}>
              Apply promo code
            </BobButton>
          }

        </>
  );

  function applyPromoCodeSubmit(e) {
    e.preventDefault();
    applyPromoCode();
  }
}

export default PromoCodeEditable;
