import * as Sentry from "@sentry/react";

function createUrl(url, query = {}) {
    const qs = Object.entries(query)
        .filter(pair => pair[1] !== undefined)
        .map(pair => pair.filter(i => i !== null).map(encodeURIComponent).join('='))
        .join('&');

    return url + (qs && '?' + qs);
}

function assertApiCallSucceeded(response, data) {
    if (response.status !== 200) {        
        const msg = `${response.url} returned HTTP ${response.status} (${(data ? JSON.stringify(data) : '')})`;
        console.error(msg);
        Sentry.captureMessage(msg);
        // throw new Error({ response, data });
    }
}

const Api = {
    applyPromoCode: async (purchaseId, promoCode) => {
        const APPLY_PROMO_CODE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/apply_promo_code`;
        try {
            const params = { purchaseId, promoCode };
            const response = await fetch(`${APPLY_PROMO_CODE_URL}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error applying promo code' });
        }
    },

    removePromoCode: async (purchaseId) => {
        const REMOVE_PROMO_CODE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/remove_promo_code`;
        try {
            const params = { purchaseId };
            const response = await fetch(`${REMOVE_PROMO_CODE_URL}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error removing promo code' });
        }
    },

    loadTicketTypes: async () => {
        const LOAD_PASSES_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/ticket_types`;
        try {
            const response = await fetch(`${LOAD_PASSES_URL}`);
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error loading tickets' });
        }
    },

    createPurchase: async (currency) => {
        try {
            const CREATE_PURCHASE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/create_purchase`;
            const url = CREATE_PURCHASE_URL;
            const params = {
                currency,
            };
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error adding to cart' });
        }

    },

    addToCart: async (purchaseId, ticketTypeId, currency) => {
        try {
            const ADD_TO_CART_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/add_to_cart`;
            const url = ADD_TO_CART_URL;
            const params = {
                purchaseId,
                ticketTypeId,
                currency,
            };
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error adding to cart' });
        }
    },

    removeFromCart: async (purchaseId, ticketIds) => {
        try {
            const ADD_TO_CART_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/remove_from_cart`;
            const url = ADD_TO_CART_URL;
            const params = { purchaseId: purchaseId, ticketIds: ticketIds };
            const response = await fetch(`${url}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(params),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error removing from cart' });
        }
    },

    startOnlinePayment: async (params) => {
        try {
            const START_ONLINE_PAYMENT_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/start_online_payment`;
            const url = START_ONLINE_PAYMENT_URL + '?' + new URLSearchParams(params);
            const response = await fetch(url);
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error starting payment' });
        }
    },

    getPaymentStatus: async (paymentId) => {
        try {
            const PAYMENT_STATUS_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/payment_status`;
            const url = `${PAYMENT_STATUS_URL}?paymentId=${paymentId}`;
            const response = await fetch(url);
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error({ error: 'Error getting payment status' });
        }
    },

    getPurchase: async (purchaseId) => {
        try {
            // await new Promise((r) => setTimeout(r, 2000));
            const GET_PURCHASE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/get_purchase`;
            const url = `${GET_PURCHASE_URL}?purchaseId=${purchaseId}`;
            const response = await fetch(url);
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            //throw new Error({ error: 'Error getting purchase' });
            return { success: false, error: 'UNKNOWN' };
        }
    },

    setPurchaseFields: async (purchaseId, body, params) => {
        const SET_PURCHASE_FIELDS_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/set_purchase_fields`;
        try {
            const queryParams = { ...params, purchaseId: purchaseId };
            const response = await fetch(createUrl(SET_PURCHASE_FIELDS_URL, queryParams), {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw error;
        }
    },

    changePaymentMethod: async (purchaseId, paymentMethod) => {
        const CHANGE_PAYMENT_METHOD_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/change_payment_method`;
        try {
            const queryParams = { purchaseId: purchaseId };
            const response = await fetch(createUrl(CHANGE_PAYMENT_METHOD_URL, queryParams), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ paymentMethod: paymentMethod }),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw error;
        }
    },

    changeCurrency: async (purchaseId, currency) => {
        const CHANGE_PAYMENT_METHOD_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/change_currency`;
        try {
            const queryParams = { purchaseId: purchaseId };
            const response = await fetch(createUrl(CHANGE_PAYMENT_METHOD_URL, queryParams), {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ currency: currency }),
            });
            const data = await response.json();
            assertApiCallSucceeded(response, data);
            return data;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            if (error.response) {
                console.log(error.response.status, error.response);
            }
            throw error;
        }
    },
};

export default Api;
