import * as Sentry from "@sentry/react";
import Tracker from '@openreplay/tracker';

let tracker = null;
if (process.env.REACT_APP_OPENREPLAY_KEY) {
  tracker = new Tracker({
    projectKey: process.env.REACT_APP_OPENREPLAY_KEY,
  });
  console.log('OpenReplay instantiated');
}

const initOpenReplay = () => {
  try {
    if (tracker) {
      tracker.start();
      // console.log('OpenReplay started');
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
}

const setOpenReplayUserId = (trackerUserId) => {
  try {
    if (tracker) {
      if (trackerUserId) {
        // console.log('Setting tracker user id: ' + trackerUserId);
        tracker.setUserID(trackerUserId);
      }
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
};

export { initOpenReplay, setOpenReplayUserId, tracker };
