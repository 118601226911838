import { Link } from 'react-router-dom';
import BobButton from './BobButton.js';

export default function ChangePaymentMethodButton({ purchaseId }) {
    return (
        <Link to={"/payment/change_payment_method?purchaseId=" + purchaseId}>
            <BobButton className="bob_button bob_button-hollow_white">Change payment method</BobButton>
        </Link>
    );
}
