import { React } from 'react';
import usePurchase from 'hooks/purchase.js';

function PromoCodeReadonly() {
  const { purchase } = usePurchase();
  const promoCode = purchase.promoCode;

  return (
    <>
      <div>
        <span className="promo_code_readonly">{promoCode == null && 'NOT APPLIED'}</span>
        <span className="promo_code_readonly">{promoCode != null && promoCode}</span>
      </div>
    </>
  );
}

export default PromoCodeReadonly;
