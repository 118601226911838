import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import RouterScrollToTop from './routerScrollToTop.js';
import { createTheme, ThemeProvider } from '@mui/material';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,    // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
    replaysOnErrorSampleRate: 1.0,    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
  });
  console.log('Sentry enabled');
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#c4a378',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: ['OswaldCustom']
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <RouterScrollToTop />
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
