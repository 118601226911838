import './BillingPage.css';
import PaymentAndTerms from './components/PaymentAndTerms.js';
import NoteField from './components/NoteField.js';
import BillingForm from './components/BillingForm.js';
import BillingSummary from './components/BillingSummary.js';
import CurrentStep from 'components/CurrentStep/CurrentStep.js';
import ConnectWithUs from 'components/ConnectWithUs/ConnectWithUs.js';
import useVerifyCartNotEmpty from 'hooks/verifyCartNotEmpty.js';
import useMobile from 'hooks/mobile.js';

function BillingPage() {
  const isMobile = useMobile();
  useVerifyCartNotEmpty();

  return (
    <>
      <h1>Billing information</h1>
      <CurrentStep index={3} />
      <div className={!isMobile ? 'min_height_container_for_connectwithus' : ''}>
        <div className="checkout_page_container">
          <div className="checkout_page_grid">
            <div className="checkout_page_left_grid">
              <BillingForm />
              <NoteField />
              <PaymentAndTerms />
            </div>
            <div className={'checkout_page_right_grid' + (!isMobile ? ' checkout_page_sticky' : '')}>
              <div>
                <BillingSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConnectWithUs />
    </>
  );
}

export default BillingPage;
