import Api from 'utils/Api.js';

const createPassesSlice = (set, get) => ({
    ticketTypes: [],

    loadTicketTypes: async () => {
        set({ loading: true });

        const handleApiResult = (result) => {
            if (result.success) return true;

            console.error('Server responded with an error: ', result.error);
            alert('Server responded with an error.');
            return false;
        };

        const result = await Api.loadTicketTypes();
        if (!handleApiResult(result)) return;

        set({
            ticketTypes: result.ticketTypes
        });
    },
});

export default createPassesSlice;