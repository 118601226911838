import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';

function OnlinePaymentPending() {
    return (
        <>
            <div className="payment_finished_page_container">

                <h1>Payment Pending</h1>

                <p className="payment_finished_page_paragraph-secondary">Your on-line payment is pending right now and we are waiting for your money.</p>
                <p className="payment_finished_page_paragraph-secondary">Don't worry, we will keep on eye on it :)</p>
                <p className="payment_finished_page_paragraph-secondary">We will notify you by e-mail once it completes or in case a problem occurs.</p>

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                </div>
                
                <br/>
                <br/>
                <br/>

                <p className="payment_finished_page_paragraph-secondary">If you need assistance, feel free to get in touch with us at <b><a className="white_link" href="mailto:info@breakonbudapest.com">info@breakonbudapest.com</a></b></p>
            </div>
        </>
    );
}

export default OnlinePaymentPending;
