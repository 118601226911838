import OrderSummaryInner from 'components/OrderSummaryInner/OrderSummaryInner.js'
import PromoCodeEditable from 'components/PromoCodeEditable/PromoCodeEditable.js';
import React from 'react'
import { useState } from 'react';
import usePurchase from 'hooks/purchase.js';
import useForms from 'hooks/forms.js';
import { useNavigate } from 'react-router-dom';
import BobButton from 'components/Buttons/BobButton.js';
import isValueEmpty from 'utils/isValueEmpty.js';

const BillingSummary = () => {
  const { savePurchase } = usePurchase();
  const { getBillingField, setBillingField } = useForms();
  const navigate = useNavigate();
  const [ proceedButtonBusy, setProceedButtonBusy ] = useState(false);
  const [ saveButtonBusy, setSaveButtonBusy ] = useState(false);
  const promoCodeDisabled = process.env.REACT_APP_PROMO_CODE_DISABLED == 'true';

  const next = async () => {
    // >> DEVELOPMENT MODE >>
    if (process.env.REACT_APP_DEV_AUTOFILL === 'true') {
      if (isValueEmpty(getBillingField('billingName'))) setBillingField('billingName', '[billing name]');
      if (isValueEmpty(getBillingField('email'))) setBillingField('email', 'adam.egressy@gmail.com');
      if (isValueEmpty(getBillingField('phone'))) setBillingField('phone', '[phone]');
      if (isValueEmpty(getBillingField('city'))) setBillingField('city', '[city]');
      if (isValueEmpty(getBillingField('street'))) setBillingField('street', '[street]');
      if (isValueEmpty(getBillingField('zipCode'))) setBillingField('zipCode', '[zipCode]');
      if (isValueEmpty(getBillingField('info'))) setBillingField('info', '[info]');
      if (getBillingField('agreeTermsConditions') !== true) setBillingField('agreeTermsConditions', true);
      if (getBillingField('agreeGdpr') !== true) setBillingField('agreeGdpr', true);
    }
    // << DEVELOPMENT MODE <<

    if (validateBilling()) {
      setProceedButtonBusy(true);
      if (await savePurchase()) {
        navigate('/review');
      } else {
        setProceedButtonBusy(false);
      }
    }
  };

  const back = async () => {
    navigate('/registration');
  };

  const save = async () => {
    setSaveButtonBusy(true);
    if (await savePurchase()) {
      window.location.href = process.env.REACT_APP_BACK_TO_BOB_SITE_URL;
    } else {
      setSaveButtonBusy(false);
    }
  };

  function validateBilling() {
    if (isValueEmpty(getBillingField('billingName'))) {
      alert('Please enter a name');
      return false;
    }
    if (isValueEmpty(getBillingField('email'))) {
      alert('Please enter an email');
      return false;
    }
    if (isValueEmpty(getBillingField('phone'))) {
      alert('Please enter your phone nunber');
      return false;
    }
    if (isValueEmpty(getBillingField('city'))) {
      alert('Please enter city');
      return false;
    }
    if (isValueEmpty(getBillingField('street'))) {
      alert('Please enter street');
      return false;
    }
    if (isValueEmpty(getBillingField('zipCode'))) {
      alert('Please enter ZIP code');
      return false;
    }

    if (getBillingField('agreeTermsConditions') != true) {
      alert('Please agree with Terms and Conditions');
      return false;
    }
    if (getBillingField('agreeGdpr') != true) {
      alert('Please agree with GDPR Statement');
      return false;
    }

    return true;
  }


  return (
    <>
      <div className="order_summary_container">
        <div className="order_summary_inner_container">
          <h3>Order summary:</h3>
          <h4>Promo code: {promoCodeDisabled && <span style={{fontWeight: 300}}>(not available yet)</span>}</h4>
          <div className="order_summary_grid">

            <PromoCodeEditable />

            <OrderSummaryInner />

            <div className="separator" />

            <BobButton onClick={next} className="bob_button-solid_gold width_100_percent" isBusy={proceedButtonBusy} >
              Proceed to final review
            </BobButton>

            <BobButton onClick={back} className="bob_button-transparent_white bob_button-solid_black_on_hover width_100_percent">
              Back to who is coming to bob
            </BobButton>

            <BobButton onClick={save} className="bob_button-transparent_white bob_button-solid_black_on_hover width_100_percent" isBusy={saveButtonBusy}>
              Save cart & back to BOB site
            </BobButton>
            
          </div>
        </div>
      </div>

    </>
  )
}

export default BillingSummary