import './CurrencySwitcher.css';
import React from 'react';
import useBobStore from 'store/bobStore.js';
import usePurchase from 'hooks/purchase.js';
import * as Sentry from '@sentry/react';

const CurrencySwitcher = () => {
  const currencies = useBobStore((state) => state.currencies);
  const selectedCurrency = useBobStore((state) => state.currency);
  const { switchCurrency: puchaseSwitchCurrency } = usePurchase();
  const setLoading = useBobStore((state) => state.setLoading);
  const purchaseId = useBobStore((state) => state.purchaseId);
  const setCurrency = useBobStore((state) => state.setCurrency);

  const switchCurrency = async (currency) => {
    try {
      setLoading(true);
      if (purchaseId != null) {
        await puchaseSwitchCurrency(currency);
      } else {
        setCurrency(currency);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    setLoading(false);
  }

  return (
    <span className="currency_container">
      {currencies.map((currency, index) => (
        <span key={currency}>
          {index != 0 && <span className="currency_container__separator">/</span>}
          <span
            className={"currency_container__currency " + (currency == selectedCurrency ? 'currency_container__currency--selected' : '') + " clickable"}
            onClick={() => switchCurrency(currency)}>
            {currency}</span>
        </span>
      ))}
    </span>
  )
}

export default CurrencySwitcher