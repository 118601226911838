import './CartIcon.css';
import React from 'react';
import { Badge } from '@mui/material';
import usePurchase from 'hooks/purchase.js';
import { ReactComponent as IconBag } from 'icons/bag.svg';
import { Link } from 'react-router-dom';

const CartIcon = () => {
  const { getCartQuantity } = usePurchase();
  
  return (
    <Link className="header_link" to={getCartQuantity() > 0 ? '/registration' : '/'}>
      {/* Note: -0.25rem is needed to offset the displacement caused by the Badge element */}
      <Badge style={{ position: 'relative', top: '-0.25rem' }} badgeContent={getCartQuantity()} color="primary" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <IconBag className="cart_icon"></IconBag>
      </Badge>
    </Link>
  )
}

export default CartIcon