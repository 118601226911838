import './PaymentInstructionsPage.css';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loader from 'components/Loader/Loader.js';
import Api from 'utils/Api.js';
import CashPaymentInfo from './components/CashPaymentInfo.js';
import TransferPaymentInfo from './components/TransferPaymentInfo.js';
import OnlinePaymentInfo from './components/OnlinePaymentInfo.js';
import CashToOrganizersPaymentInfo from './components/CashToOrganizersPaymentInfo.js';
import usePayment from 'hooks/payment.js';

function PaymentInstructionsPage() {
    const [searchParams] = useSearchParams();
    const purchaseId = searchParams.get('purchaseId');
    const [purchase, setPurchase] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadPurchase = async () => {
            const result = await Api.getPurchase(purchaseId);
            if (!result.success) {
                alert('Error loading purchase details.');
                return;

            }
            setPurchase(result.purchase);
            setLoading(false);
        };

        loadPurchase();
    }, [purchaseId]);

    return (
        <>
            {loading && <Loader />}
            {!loading && !purchase && <div>Purchase not found</div>}
            {!loading && purchase && purchase.paymentMethod == 'CASH' && <CashPaymentInfo purchase={purchase} />}
            {!loading && purchase && purchase.paymentMethod == 'CASH_TO_ORGANIZERS' && <CashToOrganizersPaymentInfo purchase={purchase} />}
            {!loading && purchase && purchase.paymentMethod == 'TRANSFER' && <TransferPaymentInfo purchase={purchase} />}
            {!loading && purchase && purchase.paymentMethod == 'ONLINE' && <OnlinePaymentInfo purchase={purchase} />}
        </>
    );
}

export default PaymentInstructionsPage;
