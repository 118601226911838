import CheckoutCart from './components/CheckoutCart.js';
import WhoIsComingForm from './components/WhoIsComingForm.js';
import WhoIsComingSummary from './components/WhoIsComingSummary.js';
import CurrentStep from 'components/CurrentStep/CurrentStep.js';
import ConnectWithUs from 'components/ConnectWithUs/ConnectWithUs.js';
import useVerifyCartNotEmpty from 'hooks/verifyCartNotEmpty.js';
import useMobile from 'hooks/mobile.js';

function WhoIsComingToBobPage() {
  const isMobile = useMobile();
  useVerifyCartNotEmpty();

  return (
    <>
      <h1>Who is coming to BOB?</h1>
      <CurrentStep index={2} />
      <div className={!isMobile ? 'min_height_container_for_connectwithus' : ''}>
        <div className="checkout_page_container">
          <div className="checkout_page_grid">
            <div className="checkout_page_left_grid">
              <CheckoutCart></CheckoutCart>
              <WhoIsComingForm></WhoIsComingForm>
            </div>
            <div className={'checkout_page_right_grid' + (!isMobile ? ' checkout_page_sticky' : '')}>
              <div>
                <WhoIsComingSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConnectWithUs />
    </>
  );
}

export default WhoIsComingToBobPage;
