import OrderSummaryInner from 'components/OrderSummaryInner/OrderSummaryInner.js'
import React from 'react'
import usePurchase from 'hooks/purchase.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import BobButton from 'components/Buttons/BobButton.js';
import PromoCodeReadonly from 'components/PromoCodeReadonly/PromoCodeReadonly.js';
import usePayment from 'hooks/payment.js';
import useBobStore from 'store/bobStore.js';

const ReviewSummary = () => {
  const { purchase, savePurchase, clearPurchaseAndForms } = usePurchase();
  const navigate = useNavigate();
  const [proceedButtonBusy, setProceedButtonBusy] = useState(false);
  const [saveButtonBusy, setSaveButtonBusy] = useState(false);
  const payment = usePayment();
  const setLoading = useBobStore((state) => state.setLoading);
  const promoCodeDisabled = process.env.REACT_APP_PROMO_CODE_DISABLED == 'true';

  const next = async () => {
    setProceedButtonBusy(true);

    const newPurchase = await savePurchase({ submit: true });
    if (newPurchase) {
      switch (newPurchase.paymentMethod) {
        case 'ONLINE':
          try {
            const onlinePaymentUrl = await payment.startOnlinePayment(newPurchase.id);
  
            // show a loader to prevent the user from seeing the next screen
            // it's only present to support a semantically correct page if the user uses the back button
            setLoading(true);
  
            clearPurchaseAndForms();
            navigate('/payment/instructions?purchaseId=' + newPurchase.id);
            window.location.href = onlinePaymentUrl;
          } catch (error) {
            setProceedButtonBusy(false);
            alert('There was an error starting your online payment. Please try again and if the problem persists, contact us at info@breakonbdapest.com');
          }
          return;
        case 'TRANSFER':
        case 'CASH':
        case 'CASH_TO_ORGANIZERS':
            clearPurchaseAndForms();
          navigate('/payment/instructions?purchaseId=' + newPurchase.id);
          return;
        default:
          console.error('Unknown payment method: ' + newPurchase.paymentMethod);
          return;
      }
    } else {
      setProceedButtonBusy(false);
    }
  };

  const back = async () => {
    navigate('/billing');
  };

  const save = async () => {
    setSaveButtonBusy(true);
    if (await savePurchase()) {
      window.location.href = process.env.REACT_APP_BACK_TO_BOB_SITE_URL;
    } else {
      setSaveButtonBusy(false);
    }
  };

  return (
    <>
      <div className="order_summary_container">
        <div className="order_summary_inner_container">
          <h3>Order summary:</h3>
          <h4>Promo code:</h4>
          <div className="order_summary_grid">

            {!promoCodeDisabled && <PromoCodeReadonly />}
            {promoCodeDisabled && <span style={{fontWeight: 300, color: '#000', textTransform: 'uppercase'}}>(not available yet)</span>}

            <OrderSummaryInner />

            <div className="separator" />

            <BobButton onClick={next} className="bob_button-solid_gold width_100_percent" isBusy={proceedButtonBusy} >
              {purchase.paymentMethod == 'ONLINE' ? 'I am happy - let me pay' : 'I am happy - submit my order'}
            </BobButton>

            <BobButton onClick={back} className="bob_button-transparent_white bob_button-solid_black_on_hover width_100_percent">
              Back to billing information
            </BobButton>

            <BobButton onClick={save} className="bob_button-transparent_white bob_button-solid_black_on_hover width_100_percent" isBusy={saveButtonBusy}>
              Save cart & back to BOB site
            </BobButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewSummary