import React from 'react'

const ConnectWithUs = () => {
  return (
    <>
      <h2>Connect with us</h2>
    </>
  )
}

export default ConnectWithUs