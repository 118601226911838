import BackToBobSiteButton from "components/Buttons/BackToBobSiteButton.js";

function PurchaseNotFound() {
    return (
        <>
            <div className="payment_finished_page_container">

                <h1>Houston, we have a problem</h1>

                <p className="change_payment_method_option_description">We looked everywhere, but we could not find the purchase this page is referring to.</p>

                <br />
                <br />

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                </div>
                
                <br/>
                <br/>
                <br/>

                <p className="payment_finished_page_paragraph-secondary">If you need assistance, feel free to get in touch with us at <b><a className="white_link" href="mailto:info@breakonbudapest.com">info@breakonbudapest.com</a></b></p>
            </div>
        </>
    );
}

export default PurchaseNotFound;
