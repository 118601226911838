import './PaymentAndTerms.css';
import { React } from 'react';
import { ReactComponent as IconHat } from 'icons/hat.svg';
import useForms from 'hooks/forms.js';
import usePurchase from 'hooks/purchase.js';
import useMobile from 'hooks/mobile.js';

function PaymentAndTerms() {
  const { getBillingField, setBillingField } = useForms();
  const { purchase } = usePurchase();
  const isMobile = useMobile();
  const REACT_APP_TERMS_AND_CONDITIONS_URL = process.env.REACT_APP_TERMS_AND_CONDITIONS_URL;
  const REACT_APP_GDPR_URL = process.env.REACT_APP_GDPR_URL;

  return (
    <div className="payment_and_terms_container">
      <div className="payment_and_terms_inner_container">
        <h3>Chose your payment method:</h3>
        <hr/>
        <ul>
          <li className={"payment_method" + (getBillingField('paymentMethod') == "ONLINE" ? " payment_method--selected" : "")} onClick={() => setBillingField('paymentMethod', "ONLINE")}>
            <i><IconHat width="2em" height="2em"></IconHat></i>
            <label><span>Online payment by card</span></label>
          </li>
          <li className={"payment_method" + (getBillingField('paymentMethod') == "TRANSFER" ? " payment_method--selected" : "")} onClick={() => setBillingField('paymentMethod', "TRANSFER")}>
            <i><IconHat width="2em" height="2em"></IconHat></i>
            {!isMobile && <label><span>Bank transfer to the organizers</span></label>}
            {isMobile && <label><span>Transfer to organizers</span></label>}
          </li>
          <li className={"payment_method" + (purchase.promoCode == null ? ' payment_method--disabled ' : '') +  (getBillingField('paymentMethod') == "CASH" ? " payment_method--selected" : "")} onClick={() => (purchase.promoCode != null) && setBillingField('paymentMethod', "CASH")}>
            <i><IconHat width="2em" height="2em"></IconHat></i>
            <label><span>Cash to ambassador</span></label>
          </li>
          <li className={"payment_method" + (getBillingField('paymentMethod') == "CASH_TO_ORGANIZERS" ? " payment_method--selected" : "")} onClick={() => setBillingField('paymentMethod', "CASH_TO_ORGANIZERS")}>
            <i><IconHat width="2em" height="2em"></IconHat></i>
            <label><span>Cash to organizers at check-in</span></label>
          </li>
        </ul>
        <hr/>
        <h4>Please note:</h4>
        <p>
          In case or payment in cash to your Ambassador, you have 7 days from the purchase to give the money to your Ambassador in person, otherwise we might delete your purchase.
        </p>
        <p>
          In case of direct transfer to the Organizers, please contact us and check the availablility of payment via Revolut / Wise. You have 7 days to transfer us the money or the purchase might get invalid.
        </p>
        <ul className="terms_and_conditions">
          <li 
            className={"clickable " + (getBillingField('agreeTermsConditions') ? "terms_and_conditions-selected" : "")} 
            onClick={() => setBillingField('agreeTermsConditions', !getBillingField('agreeTermsConditions'))}>
            <i className="terms_and_conditions--icon_with_asterisk">
              <IconHat width="2em" height="2em" className="svg"></IconHat>
            </i>
            <label>I have read and hereby I agree to the <a href={REACT_APP_TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer" onClick={(e) => openTermsAndConditions(e)}>Terms and Conditions *</a>.</label>
          </li>
          <li 
            className={"clickable " + (getBillingField('agreeGdpr') ? "terms_and_conditions-selected" : "")} 
            onClick={() => setBillingField('agreeGdpr', !getBillingField('agreeGdpr'))}>
            <i className="terms_and_conditions--icon_with_asterisk">
              <IconHat width="2em" height="2em"></IconHat>
              </i>
            <label>I agree with processing my personal data necessart to fulfill the pass purchase and at the event. I have read and agreed on the <a href={REACT_APP_GDPR_URL} target="_blank" rel="noreferrer" onClick={(e) => openGdpr(e)}>GDPR statement *</a>.</label>
          </li>
          <li 
            className={"clickable " + (getBillingField('agreeKeepPersonalInfo') ? "terms_and_conditions-selected" : "")} 
            onClick={() => setBillingField('agreeKeepPersonalInfo', !getBillingField('agreeKeepPersonalInfo'))}>
            <i>
              <IconHat width="2em" height="2em">
                </IconHat></i>
            <label>BOB can keep my personal data after the event and send me news via email.</label>
          </li>
        </ul>
      </div>
    </div>
  );

  function openTermsAndConditions(e) {
    e.stopPropagation();
  }

  function openGdpr(e) {
    e.stopPropagation();
  }
}

export default PaymentAndTerms;
