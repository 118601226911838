import './BuyPassCart.css';
import { React } from 'react';
import CartCore from 'components/CartCore/CartCore.js';
import { useNavigate } from 'react-router-dom';
import usePurchase from 'hooks/purchase.js';
import BobButton from 'components/Buttons/BobButton.js';

function BuyPassCart() {
  const { getCartQuantity } = usePurchase();
  const navigate = useNavigate();

  return (
    <>
        <div className="buy_pass_cart_container">
          <div className="buy_pass_cart_table_container">

            {/* TODO: fix classes/styles */}
            <div className="checkoutcart_header" style={{ fontSize: '1.25rem' }}>
              <h3>Your selection:</h3>
            </div>

            <CartCore></CartCore>

            <div className="continue_button_container">

              <BobButton
                disabled={getCartQuantity() == 0}
                className="bob_button bob_button-solid_white"
                onClick={() => navigate('/registration')}>
                Proceed to Who is coming to BOB?
              </BobButton>
            </div>
          </div>
        </div>
    </>
  );
}

export default BuyPassCart;
