import Api from 'utils/Api.js';
import usePayment from 'hooks/payment.js';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';
import BobButton from 'components/Buttons/BobButton.js';

function ChangePaymentMethod({ purchase }) {
    const payment = usePayment();
    const navigate = useNavigate();
    const [switchToOnlinePaymentBusy, setSwitchToOnlinePaymentBusy] = useState(false);
    const [switchToBankTransferBusy, setSwitchToBankTransferBusy] = useState(false);
    const [switchToCashPaymentBusy, setSwitchToCashPaymentBusy] = useState(false);

    const handleApiResult = (result) => {
        if (result.success) {
            return true;
        }
        setSwitchToOnlinePaymentBusy(false);        
        setSwitchToBankTransferBusy(false);
        setSwitchToCashPaymentBusy(false);
        console.error('Server responded with an error: ', result.error);
        alert('Server responded with an error.');
        return false;
    }

    // actions

    const switchToTransferPayment = async () => {
        setSwitchToBankTransferBusy(true);

        const result = await Api.changePaymentMethod(purchase.id, 'TRANSFER');
        if (!handleApiResult(result)) return;

        navigate('/payment/instructions?purchaseId=' + result.purchase.id);
    }

    const switchToCashPayment = async () => {
        setSwitchToCashPaymentBusy(true);

        const result = await Api.changePaymentMethod(purchase.id, 'CASH');
        if (!handleApiResult(result)) return;

        navigate('/payment/instructions?purchaseId=' + result.purchase.id);
    }

    const switchToOnlinePayment = async () => {
        setSwitchToOnlinePaymentBusy(true);

        const result = await Api.changePaymentMethod(purchase.id, 'ONLINE');
        if (!handleApiResult(result)) return;

        try {
            const onlinePaymentUrl = await payment.startOnlinePayment(result.purchase.id);
            window.location.href = onlinePaymentUrl;
        } catch (error) {
            setSwitchToOnlinePaymentBusy(false);
            alert('There was an error starting your online payment. Please try again and if the problem persists, contact us at info@breakonbdapest.com');
        }
    }

    // buttons

    const SwitchToOnlinePaymentButton = () =>
        <BobButton className="bob_button bob_button-hollow_white" onClick={switchToOnlinePayment} isBusy={switchToOnlinePaymentBusy}>Pay online</BobButton>;

    const SwitchToTransferPaymentButton = () =>
        <BobButton className="bob_button bob_button-hollow_white" onClick={switchToTransferPayment} isBusy={switchToBankTransferBusy}>Bank transfer</BobButton>;

    const SwitchToCashPaymentButton = () =>
        <BobButton className="bob_button bob_button-hollow_white" onClick={switchToCashPayment} isBusy={switchToCashPaymentBusy}>Cash to ambassador</BobButton>;

    return (
        <>
            <h1>Change method of payment?</h1>
            <div className="payment_finished_page_container">
                <p className="payment_finished_page_paragraph-secondary"><b>You've landed on this page because something with your payment went wrong or you chose to change your payment method.</b></p>
                <p className="payment_finished_page_paragraph-secondary"><b>Don't worry, there is always a fix to every problem 😎</b></p>

                <h3 className="change_payment_method_option">You can pay online</h3>
                <p className="change_payment_method_option_description">Pay securely with your credit card, debit card, ApplePay or Google Pay.</p>
                <SwitchToOnlinePaymentButton />

                <h3 className="change_payment_method_option">You can pay with bank transfer</h3>
                <p className="change_payment_method_option_description">If you cannot pay with one of the options above but you can make a wire transfer using SWIFT/IBAN then this option might be the right one for you. For instructions, please click the button below:</p>
                <SwitchToTransferPaymentButton />

                {purchase.promoCode && <>
                    <h3 className="change_payment_method_option">You can pay in cash to your ambassador</h3>
                    <p className="change_payment_method_option_description">If cash is the best way for you to pay and you are able to meet your ambassador in person then you might want to click the last button below:</p>
                    <SwitchToCashPaymentButton />
                </>}

                <h3 className="change_payment_method_option">Still in doubt?</h3>
                <p className="change_payment_method_option_description">If you need assistance, feel free to get in touch with us at <b><a className="white_link" href="mailto:info@breakonbudapest.com">info@breakonbudapest.com</a></b></p>
                <BackToBobSiteButton />
            </div>
        </>
    );

}

export default ChangePaymentMethod;
