import './ChangePaymentMethodPage.css';
import Api from 'utils/Api.js';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from 'components/Loader/Loader.js';
import PurchaseNotFound from './components/PurchaseNotFound.js';
import InvalidPurchaseState from './components/InvalidPurchaseState.js';
import ChangePaymentMethod from './components/ChangePaymentMethod.js'

function ChangePaymentMethodPage() {
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const purchaseId = searchParams.get('purchaseId');
    const [purchase, setPurchase] = useState(true);

    useEffect(() => {
        const loadPurchase = async () => {
            const result = await Api.getPurchase(purchaseId);
            if (!result.success) {
                alert('Error loading purchase details.');
                return;

            }

            setPurchase(result.purchase);
            setLoading(false);
        };

        loadPurchase();
    }, [purchaseId]);

    if (loading) return <Loader />;
    if (!purchase) return <PurchaseNotFound />
    if (purchase.status == 'SUBMITTED') return <ChangePaymentMethod purchase={purchase} />
    if (purchase.status == 'PAID') return <InvalidPurchaseState message="This purchase has been fully paid." />
    if (purchase.status == 'CANCELLED') return <InvalidPurchaseState message="This purchase has been cancelled." />
    if (purchase.status == 'DELETED') return <InvalidPurchaseState message="This purchase has been deleted." />
    if (purchase.status == 'NEW') return <InvalidPurchaseState message="This purchase has not been completed yet." />
    return <InvalidPurchaseState message="This purchase is in an invalid state." />
}

export default ChangePaymentMethodPage;
