import ChangePaymentMethodButton from 'components/Buttons/ChangePaymentMethodButton.js';
import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';
import RetryPaymentButton from 'components/Buttons/RetryPaymentButton.js';

function OnlinePaymentInfo({ purchase }) {
    return (
        <>
            <h1>Checkout complete</h1>
            <div className="payment_finished_page_container">
                <p className="payment_finished_page_paragraph-secondary">Smart! 😎 You've decied to pay on-line.</p>
                <p>Please click the <b>Pay Online</b> below to continue. You can also change payment method if you changed your mind.</p>

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                    <ChangePaymentMethodButton purchaseId={purchase.id} />
                    <RetryPaymentButton purchaseId={purchase.id} label="Pay Online" />
                </div>
            </div>
        </>
    );
}

export default OnlinePaymentInfo;
