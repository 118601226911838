import './ChooseYourPassPage.css';
import PassList from './components/PassList.js';
import BuyPassCart from './components/BuyPassCart.js';
import CurrentStep from 'components/CurrentStep/CurrentStep.js';
import ConnectWithUs from 'components/ConnectWithUs/ConnectWithUs.js';

function ChooseYourPassPage() {

  return (
    <>
      <div className="choose_your_pass_container">
        <h1>Choose your pass</h1>
        <CurrentStep index={1} />
        <PassList></PassList>
        <BuyPassCart></BuyPassCart>
        <ConnectWithUs />
      </div>
    </>
  );
}

export default ChooseYourPassPage;
