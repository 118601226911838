import ChangePaymentMethodButton from 'components/Buttons/ChangePaymentMethodButton.js';
import BackToBobSiteButton from 'components/Buttons/BackToBobSiteButton.js';

function CashToOrganizersPaymentInfo({ purchase }) {
    return (
        <>
            <h1>Checkout complete</h1>
            <div className="payment_finished_page_container">
                <p className="payment_finished_page_paragraph-secondary">You've decied to <b>pay in cash to the organizers</b>.</p>
                <span style={{ fontSize: '4rem' }}>💰</span>
                <p></p>

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                    <ChangePaymentMethodButton purchaseId={purchase.id} />
                </div>
            </div>
        </>
    );
}

export default CashToOrganizersPaymentInfo;
