import './WhoIsComingForm.css';
import { React } from 'react';
import { ReactComponent as IconHat } from 'icons/hat.svg';
import useForms from 'hooks/forms.js';
import usePurchase from 'hooks/purchase.js';
import useMobile from 'hooks/mobile.js';

function TicketField({ ticketId, label, fieldName }) {
  const { getTicketField, setTicketField } = useForms();

  return <>
    <label>{label}</label>
    <input value={getTicketField(ticketId, fieldName) ?? ""} onChange={(e) => setTicketField(ticketId, fieldName, e.target.value)}></input>
  </>
}

function TicketPartyField({ ticketId }) {
  const { isTicketOptionEnabled, toggleTicketOption, partyDays } = useForms();
  const isMobile = useMobile();

  return <>
    <ul className={!isMobile ? 'hat_checkboxes hat_checkboxes-horizontal' : 'hat_checkboxes hat_checkboxes-vertical'}>
      {partyDays.map((partyDay) => (
        <li
          key={partyDay}
          className={"clickable" + (isTicketOptionEnabled(ticketId, 'party', partyDay) ? ' hat_checkbox--selected' : '')}
          onClick={() => toggleTicketOption(ticketId, 'party', partyDay)}>
          <i><IconHat width="2em" height="2em"></IconHat></i>
          <label><span>{partyDay}</span></label>
        </li>
      ))}
    </ul>
  </>
}

function WhoIsComingTickets() {
  const { purchase } = usePurchase();
  const isMobile = useMobile();

  return (
    <>
      {purchase.tickets.map((ticket, idx) => {
        const ticketId = ticket.id;

        return (
          <div key={`pass_${ticketId}`} className="pass_registration_container bob_form_container">
            <div className="bob_form_inner_container bob_form">
              <h3 className="no_top_padding"><u>Pass #{idx + 1}:</u> {ticket.ticketType.name}</h3>
              <div className="form_fixed_grid bob_form_bold">
                <TicketField label="Name:*" fieldName="clientName" ticketId={ticketId} />
                <TicketField label="E-mail:*" fieldName="clientEmail" ticketId={ticketId} />
              </div>
              <div className={!isMobile ? 'form_flexible_grid' : 'form_mobile_grid'}>
                <h4>Tell us more:</h4>
                <div>
                  <TicketField label="When did you start dancing?" fieldName="danceExperience" ticketId={ticketId} />
                </div>
                <div>
                  <TicketField label="Have you met BOB before?" fieldName="lastEvent" ticketId={ticketId} />
                </div>
                <div>
                  <TicketField label="Who is your favorite artist?" fieldName="favoriteArtists" ticketId={ticketId} />
                </div>
                <div>
                  <TicketField label="Who is your favorite DJ?" fieldName="favoriteDj" ticketId={ticketId} />
                </div>
                <label className={'more_padding_on_top' + (isMobile ? ' more_padding_at_bottom' : '')}>When will you party us?</label>
                <TicketPartyField ticketId={ticketId} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default WhoIsComingTickets;
