import usePayment from 'hooks/payment.js';
import BobButton from './BobButton.js';

export default function RetryPaymentButton({ purchaseId, label }) {
    const payment = usePayment();

    const retryPayment = async () => {
        try {
            const onlinePaymentUrl = await payment.startOnlinePayment(purchaseId);
            if (onlinePaymentUrl) {
                window.location.href = onlinePaymentUrl;
            }
        } catch (error) {
            alert('There was an error starting your online payment. Please try again and if the problem persists, contact us at info@breakonbdapest.com');
        }
    };

    return (
        <BobButton
            className="bob_button bob_button-solid_white bob_button-solid_white--white_border"
            onClick={retryPayment}>{label ?? "Retry payment"}</BobButton>
    );
}
