import BackToBobSiteButton from "components/Buttons/BackToBobSiteButton.js";

function InvalidPurchaseState({ message }) {
    return (
        <>
            <h1>We are sorry...</h1>
            <div className="payment_finished_page_container">
                <p className="payment_finished_page_paragraph-secondary">
                    It is not possible to pay this purchase because:<br/>
                    <br/>
                    <b>{message}</b>
                </p>

                <br />
                <br />

                <div className="payment_finished_page_container_buttons">
                    <BackToBobSiteButton />
                </div>
                
                <br/>
                <br/>
                <br/>

                <p className="payment_finished_page_paragraph-secondary">If you need assistance, feel free to get in touch with us at <b><a className="white_link" href="mailto:info@breakonbudapest.com">info@breakonbudapest.com</a></b></p>
            </div>
        </>
    );
}

export default InvalidPurchaseState;
