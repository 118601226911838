const createFormSlice = (set, get) => ({

    form: {
    },
    formDefaults: {
        'billing-paymentMethod': 'ONLINE',
        'billing-country': 'HU',
    },

    getForm: () => {
        const { form, formDefaults } = get();
        return { form, formDefaults };
    },

    setForm: (form) => {
        set({
            form: JSON.parse(JSON.stringify(form))
        });
    },
});

export default createFormSlice;