import Api from 'utils/Api.js';
import * as Sentry from "@sentry/react";

const usePayment = () => {
    const handleApiResult = (result) => {
        if (result.success) {
            return true;
        }
        console.error('Server responded with an error: ', result.error);
        alert('Server responded with an error.');
        return false;
    }

    const startOnlinePayment = async (purchaseId) => {
        try {
            const result = await Api.startOnlinePayment({ purchaseId });
            if (!handleApiResult(result)) return;

            return result.redirectUrl;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw new Error('Error starting online payment');
        }
    };

    const getPaymentStatus = async (paymentId) => {
        try {
            var result = await Api.getPaymentStatus(paymentId);
            if (!handleApiResult(result)) return;

            return result.status;
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    };

    return {
        startOnlinePayment,
        getPaymentStatus,
    };
};

export default usePayment;
