import usePurchase from 'hooks/purchase.js';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useVerifyCartNotEmpty = () => {
  const { purchase } = usePurchase();
  const navigate = useNavigate();

  useEffect(() => {
    if (purchase.tickets.length == 0) {
      console.log('Cart is empty -> navigating home');
      navigate('/');
    }
  }, [navigate]); // DON'T put in this: purchase.tickets.length -- we only need this code to run once
}

export default useVerifyCartNotEmpty
